.meme-generator {
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 14vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-inline: 8%;
  }
  
  .meme-templates {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .template-options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 10px;
    margin-top: 2%;
  }
  
  .template-image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .template-image.selected {
    border-color: #007bff;
  }
  
  .meme-editor {
    margin: 20px auto;
    max-width: 500px;
  }
  
  .meme-canvas {
    position: relative;
    /* width: 100%; */
    width: 500px;
    max-height: 580px;
    border: 2px solid #ccc;
    margin: 20px auto;
    object-fit: cover;
    overflow: hidden;
  }
  
  .meme-image {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .draggable-text {
    position: absolute;
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    padding: 5px;
    cursor: move;
  }

  .text-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 4% ;
  }
  
  .text-inputs label {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    width: 80%;
    font-size: 16px;
    font-weight: 900;
  }
  
  .text-inputs input {
    width: 60%;
    padding: 5px;
    margin-top: 5px;
    font-size: 14px;
    background-color: var(--color-white);
    margin-left: 2%;
    border-radius: 4px;

  }

  @media screen and (max-width: 992px) {
    .meme-generator {
        text-align: center;
        font-family: Arial, sans-serif;
        margin-top: 14vh;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-inline: 8%;
      }
  }