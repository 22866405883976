.header {
  height: 12vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-block: 1%;
  padding-inline: 2%;
  position:fixed;
  box-shadow: 0px 0px 20px var(--color-white);
  backdrop-filter: blur(200px);
  z-index: 5;
}

.header-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  margin-left: 0px;
  margin-bottom: 0px;
}

.title {
  margin: 2px;
  font-size: 32px;
  font-weight: 700;

  color: var(--color-yellow)
}

.icon-title {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-white);
}


/* Navbar Links Container */

.links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  width: 80%;
  height: 100%;
}

.links-style{
  margin-inline: 1%;
  padding-inline: 2%;
  padding-block: 1%;
  font-weight: 700;
  font-size: 18px;
  color: var(--color-white);
  transition: 0.4s;
  text-wrap: nowrap;
}


.links-style:hover, .selected-link{
  margin-inline: 2%;
  padding-inline: 2%;
  padding-block: 1%;
  border-radius: 10px;
  background-color: var(--background-color-primary);
  color: var(--color-blue);
  transition: 0.4s;
}



/* FOR TABLETS */
@media screen and (max-width: 992px) {
  .title-container {
    width: 80%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0;

  }
  .title{
    font-size: 28px;
    color: var(--background-color-primary);

  }
  .icon-title {
    color: var(--color-white);
  }
  .nav-burger{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height:36px;
  }
  .links-container {
    position: fixed;
    overflow-y: scroll;
    top: 12vh;
    left: 0%;
    background-color: var(--color-black);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 63.6vh;
    transition: left 1s;
    margin-inline: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-style: groove;
    border-width: 4px;
    border-color: var(--secondary-color);
    border-radius: 0 0 10px 0;
    box-shadow: 2px 2px 8px var(--color-white);
  }

  .links-style{
    text-align: start;
    width: 100%;
    border-style: solid;
    border-width: 2px;
    border-color: var(--primary-color);
    border-radius: 0px;
    font-size: 24px;
    color: var(--color-white);
    padding-left: 4%;
    padding-block: 8%;
    margin: 0;
  }
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .title-container {
    font-size: 64%;
    width: 100%;
    align-items: center;
  }
  .title {
    color: var(--color-red);
  }
  .icon-title {
    color: var(--color-white);
    width: 90%;
    align-items: end;

  }
}





/* Web3 Button */

.web3buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-inline: 6px;
}


.online {
  background-color: var(--primary-color); 
  width: 10px;
  height: 10px;
  border-radius: 10px ;
  margin: 5px;
}
