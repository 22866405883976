.faq-section {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 4vh;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

}

.faq-title{
    font-size: 80px;
    color: var(--color-red);
    text-shadow: 2px 2px var(--color-blue);
    margin-top: 6%;
    margin-bottom: 2%;
    z-index: 2;
}


.faq-content-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 4%;
}

.faq-card{
    width: 80%;
    border-style: solid;
    padding-block: 1.6%;
    padding-left: 4%;
    margin-block: 1%;
    word-wrap: break-word;
}

.link-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 0 32px 0 32px;
}

@media screen and (min-width: 768px) {
    .link-container {
        width: 60%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1024px) {
    .link-container {
        width: 35%;
    }
}

@media screen and (min-width: 1440px) {
    .link-container {
        width: 30%;
    }
}
.link-icon-box{ 
    /* margin-inline: 16%; */
    background-color: var(--color-red);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 2%;
    border-radius: 8px;
    box-shadow: 4px 4px var(--color-white);
    color: var(--color-black);
    transition: 0.6s;
    margin-inline: 2%;
}
.link-icon-box:hover{
  transition: 0.4s;
  box-shadow: 0px 0px 0px var(--color-white);
  transform: translate(4px, 4px);
}
.link-icon-image{
    height: 40px;
    width: 50px;
}

.end-box{
    margin-block: 4%;
    width: 68%;
    padding: 0 32px 0 32px;
}