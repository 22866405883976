
.story-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    
}

.story-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    
}

.image-div{
    height: 60%;
    width: 36%;
    border-radius: 4px;
    box-shadow: 8px 8px 4px var(--color-purple);
}

.story-div{
    height: 50%;
    width: 36%;
    backdrop-filter: blur(12px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
    
}

.story-content-size{
    font-size: 18px;
    color: var(--color-yellow);
}

@media screen and (max-width: 1200px){
    .image-div{
        width: 40%;
        height: 50%;
    }
    .story-div{
        width: 50%;
    }
    .story-content-size{
        font-size: 16px;
    }
}

@media screen and (max-width: 800px){
    
    .story-box{
        flex-direction: column;
    }
    .image-div{
        width: 86%;
        height: 24%;
        margin-top: 4%;
        margin-bottom: 2%;
    }
    .story-div{
        width: 86%;
        height: 70%;
        margin-top: 0%;
        margin-left: 0;
    }
    .story-content-size{
        font-size: 12px;
    }
}

@media screen and (max-width: 380px) {
    
    .story-content-size{
        font-size: 10px;
    }
}