.chat-page{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-box{
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 60%;
    height: 68vh;
}

.sidebar{
    width: 30%;
    height: 100%;
    background-color: rgb(53, 53, 50);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-inline: 2%;
    padding-block: 2%;

}

.selected-proposal-button{
    margin-block: 2%;
    border-style: solid;
    border-radius: 8px;
    padding: 4% 4%;
    background-color: var(--color-blue);
}
.proposal-button{
    margin-block: 2%;
    border-style: solid;
    border-radius: 8px;
    padding: 4% 4%;
    background-color: var(--color-offblack);
}

.message-box{
    width: 70%;
    height: 100%;
    background-color: yellowgreen;
}

.titlebar{
    height: 10%;
    width: 100%;
    background-color: brown;
}

.content-box{
    height: 76%;
}

.input-box{
    height: 14%;
    width: 100%;
    background-color: brown;
}