.rektnomics-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow:visible;
}

.rektnomics-box {
  width: 90%;
  height: 80%;
  margin-inline: 5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4%;
  align-items: center;
}
.rekt-image {
  width: 100%;
  height: 76%;
  border-radius: 12px;
  box-shadow: -8px 8px 8px 0px var(--color-purple);
  
}

.rekt-info {
  width: 100%;
  height: 76%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rekt-info-card{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    backdrop-filter: blur(50px);
    border-radius: 12px;
    margin-block: 4%;
    padding-block: 4%;
    padding-inline: 4%;
}

.rekt-utility {
  width: 100%;
  height: 76%;
  border-radius: 12px;
  backdrop-filter: blur(50px);
  padding-block: 8%;
  box-shadow: 6px -6px 8px 0px var(--color-purple);
}

.rekt-utility h1{
    font-size: 24px;
    text-shadow: 2px 2px var(--color-blue);
}

.rekt-utility-box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    padding-inline: 12%;
    height: 80%;
    margin-block: 4%;
}
.rekt-utility-div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rekt-utility-div p{
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 2%;
}
.rekt-icon{
    height: 24px;
    width: 24px;
    color: var(--color-red);
}

@media screen and (max-width: 1200px) and (min-width:600px) {
    .rektnomics-box {
        width: 96%;
        height: 80%;
        margin-inline: 0%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0%;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
    .rekt-image{
        height: 80%;
        width: 80%;
        
      }
      .rekt-info{
        height: 90%;
        width: 80%;
        grid-row: 2;
    
      }
      .rekt-utility{
        height: 90%;
        width: 100%;
        grid-row: 1/3;
      }
     

}

@media screen and (max-width: 600px) {
    .rektnomics-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    .rektnomics-box {
        width: 90%;
        height: 90%;
        margin-inline: 5%;
        margin-block: 8%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2%;
        align-items: center;
        justify-items: center;
        justify-content: center;
      }
      .rekt-image{
        height: 80%;
        width: 80%;
        
      }
      .rekt-info{
        height: 80%;
        width: 80%;
        

      }
      .rekt-utility{
        height: 100%;
        width: 80%;

      }
      .rekt-utility-box{
        padding-inline: 16%;
      }
}

@media screen and (max-width: 380px){
  .rekt-image{
    height: 30vh;
    width: 80%;
    
  }
    .rektnomics-box{
        justify-items: center;
    }
}
