.roadmap-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100vh;
    margin-inline: 10%;
}

.roadmap-box{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    width: 100%;
    height: 70vh;
    justify-items: center;
    align-items: center;
    margin-block: 4%;
}

.roadmap-card{
    height: 70%;
    width: 80%;
    padding-block: 2%;
    padding-inline: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 16px;
    box-shadow: 6px 6px 12px 2px var(--color-blue), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.4s;
}
.roadmap-card:hover{
    scale: 1.1;
    transition: 0.4s;
}
.roadmap-card h1{
    backdrop-filter: blur(50px);
    border-radius: 16px;
    padding-inline: 16px;
    padding-block: 32px;
    text-align: center;
    color: var(--color-yellow);
    text-shadow: 2px 2px var(--color-blue);
}
/* 
.road-1{
    grid-row: 1 ;
    grid-column: 1 ;
}
.road-2{
    grid-row: 1 ;
    grid-column: 2 ;
}
.road-3{
    grid-row: 1 ;
    grid-column: 3 ;
}
.road-4{
    grid-row: 1 ;
    grid-column: 4 ;
}
.road-5{
    grid-row: 2 ;
    grid-column: 1 ;
}
.road-6{
    grid-row: 2 ;
    grid-column: 2 ;
}
.road-7{
    grid-row: 2 ;
    grid-column: 3 ;
}
.road-8{
    grid-row: 2 ;
    grid-column: 4 ;
} */


@media screen and (max-width: 1200px) {
    .roadmap-box{
        grid-template-columns: repeat(3, 1fr);
    }
    .roadmap-card{

    }
}

@media screen and (max-width: 800px) {
    .roadmap-box{
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
    }
    .roadmap-card{
        width: 90%;
        height: 80%;
    }
    .roadmap-card h1{
        padding-inline: 8px;
        padding-block: 16px;
        font-size: 16px;
    }
}



