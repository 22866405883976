.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: var(--color-black); */
  background: transparent;
  color: var(--color-white);
  position:relative;
}


.body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0vh;
}

a {
  color: var(--secondary-color);
}


button {
  border-radius: 6px;
  border-width: 0px;
  margin-block: 10px;
  margin-block: 10px;
  padding-block: 12px;
  padding-inline: 24px;
  background-color: var(--color-red);
  color: var(--color-white);
  box-shadow: 4px 4px 0px 2px var(--color-blue);
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  font-size: large;
  font-weight: 400;
  transition: 0.4s;
}

button:hover {
  transition: 0.4s;
  scale: 1;
  box-shadow: 0px 0px 0px var(--color-white);
  transform: translate(4px, 4px);
}


input {
  width: 250px;
  padding-block: 5px;
  padding-inline: 10px;
  font-size: 32px;
  background-color: var(--secondary-color);
  border-width: 0;
}

input:focus{
  outline: none;
}

/* @media all and (max-width: 600px) {
  .body{
    margin-top: 4vh;
  }
} */
