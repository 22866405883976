@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

body {
  margin: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color-secondary);
  color: var(--color-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --header-height: 3.5rem;
  --color-white: #FFFFFF;
  --color-offwhite: #cccbcb;
  --color-black: #191e29;
  --color-offblack: #232E21;
  --color-red: #e7255e;
  --color-blue: #52959d;
  --color-yellow: #fcd25a;
  --color-magenta: #3B1C32;
  --color-purple: #D7B8F3;
  --color-green: #51A3A3;

  --background-color-primary: #fcd25a;
  --background-color-secondary: #3B1C32;
  --background-color-third: #D81E5B;
  --color-first: #D7B8F3;
  --color-second: #51A3A3;
  
  --body-font: "Montserrat", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
 }

 ul {
  list-style: none;
 }
 
 a {
  text-decoration: none;
  text-decoration: dotted underline;
 }
 
