
.pfp-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4%;
    width: 100%;
    height: 70%;
    justify-items: center;
    overflow-x: hidden;
}

.pfp-instructions{
    width: 20vw;
    height: 60vh;
    justify-items: center;
}

.pfp-instruct-point{
    background-color: var(--color-red);
    backdrop-filter: blur(100px);
    border-radius: 8px;
    width: 90%;
    margin-block: 8%;
    padding-block: 4%;
    box-shadow: 4px 6px 0px var(--color-blue);
    transition: 0.4s;
}

.pfp-instruct-point:hover{
    transition: 0.4s;
    box-shadow: 0px 0px;
    transform: translate(4px, 6px);
}

.pfp-image-box{

}

.pfp-image{
    position: relative;
    overflow: hidden;
    width: 30vw;
    height: 45vh;
    transition: 1s;

}
/* .pfp-image:hover{
    scale: 1.05;
    transition: 1s;
} */

.composite-layer {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1; /* Higher layers will stack on top */
    border-radius: 8px;
}

.mint-button-box{
    width: 100%;
    margin-block: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.pfp-options{
    width: 30vw;
    padding-inline: 4%;

}

.option-navbar{
    display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.scroll-button {
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .scroll-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }

.options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    overflow: hidden;
    width: 50%; /* Adjust as needed */
    transition: transform 0.5s ease-in-out; /* Add smooth transition */
    position: relative;

  }
  
  .option {
    flex: 1;
    text-align: center;
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 400;
    opacity: 0.8;
    transform: scale(0.9); /* Scale down non-current items */
    transition: all 0.3s ease; 
  }
  
  .option.current {
    font-weight: 900;
    font-size: 18px;
    color: var(--color-red);  
    opacity: 1;

    transform: scale(1); /* Scale up current item */

  }


  .option-layers-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4%;
    width: 100%;
    
  }

 .selected-option-layer{
    border-style: solid;
    box-shadow: -2px -2px 4px var(--color-red);
    border-color: var(--color-red);
    width: 100%;
    height: 100%;
    border-radius: 8px;
 }

  .option-layer{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 1px 1px 4px var(--color-blue);
    border-color: var(--color-blue);
    border-style: solid;
    border-width: 0.1px;
  }